import _clone from 'lodash/clone';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { getKeys } from '@atc/bonnet-parameters';
import { getPath, pageNames } from '@atc/bonnet-paths';

const { VEHICLE_DETAILS } = pageNames;

const PRIORITY_LABEL = {
    5: 'FREE',
    10: 'STANDARD',
    15: 'STANDARD_PLUS',
    17: 'BASIC',
    20: 'FEATURED',
    30: 'PREMIUM',
};

const PSX_VALUE_PROP_IMAGES = [
    {
        alt: 'Private Seller Exchange offers chat with Verified sellers, a free vehicle history report, and secure payment.',
        src: '/content/static/img/psx/psx_value_prop_1.jpg',
    },
    {
        alt: 'Private Seller Exchange offers clean title vehicles, warranty and GAP protection, and seamless online payment in select states.',
        src: '/content/static/img/psx/psx_value_prop_2.jpg',
    },
];

export function addPsxValuePropImages(images) {
    const { sources = [] } = images || {};
    // if there is no image
    // -> do nothing.
    if (!sources || sources.length === 0) {
        return;
    }
    // if there are less than 4 images
    // -> place value prop images at the last positions
    // if there are more than 4 but less than 7 images
    // -> place value prop images at the 3rd and last position
    // if there are 7 or more images
    // -> place value prop images at the 3rd and 7th position
    [sources.length < 4 ? sources.length : 2, sources.length < 7 ? sources.length + 1 : 6].forEach((position, index) => {
        sources.splice(position, 0, PSX_VALUE_PROP_IMAGES[index]);
    });
}

export function replaceInventoryImageUrlServer(source, replaceStr) {
    return source.toString().replace(/images.qa.autotrader.com|images.autotrader.com/, replaceStr);
}

export function replaceImageServerInUrl(obj, path, replaceStr) {
    const imageSrc = _get(obj, path, null);

    if (imageSrc && replaceStr) {
        _set(obj, path, replaceInventoryImageUrlServer(imageSrc, replaceStr));
    }
}

export function setPhoneVisible(objWithPhone, displayPhoneNumber) {
    if (displayPhoneNumber && objWithPhone) {
        if (objWithPhone.phone) {
            objWithPhone.phone.visible = true;
        } else if (objWithPhone.owner && objWithPhone.owner.phone) {
            objWithPhone.owner.phone.visible = true;
        }
    }
}

export function showDealerAsOwnerName(listing, listingOwner, hideOwnerName) {
    if (hideOwnerName && listing?.priority
        && ((parseInt(listing?.priority, 10) <= 10))
        && !listing?.listingTypes?.some((type) => type.code === 'NEW')
        && !listingOwner?.privateSeller) {
        listing.ownerName = 'Dealer';
    } else if (listingOwner?.privateSeller) {
        // Undo any other page overrides
        listing.ownerName = listingOwner?.name;
    }
}

export function translateListingPriority(listing) {
    if (PRIORITY_LABEL[listing.priority]) {
        listing.priority = PRIORITY_LABEL[parseInt(listing.priority, 10)];
    }
}

export async function buildVDPBaseUrl(ctx) {
    const { req, data } = ctx;

    const target = 'lsc';

    // Only apply this query for vdp url
    const vdpQuery = _clone(ctx.query);

    // set new search to false for analytics, any page that is referred from the vdp should not be a new search
    const newSearchKey = getKeys('newSearch')[target];
    vdpQuery[newSearchKey] = false;
    // remove listingId from the search query since we only need the listingId of the vehicle being clicked on
    vdpQuery.listingId = null;
    // remove clickType from the search query since we are building the vdp base url.
    vdpQuery.clickType = null;
    // remove lastExec from query
    delete vdpQuery.lastExec;
    // remove lastExec from the req query
    if (req?.query) {
        delete req.query.lastExec;
    }

    return getPath(VEHICLE_DETAILS, vdpQuery, {
        brand: data.brand,
        referrer: true,
        req,
        target,
        basePath: true,
        useUpdatedVdpPath: true,
    });
}

export const obfuscateCarfaxHref = (listing) => {
    const carfaxProductTileFiltered = listing.productTiles?.filter((tile) => tile?.tileType?.startsWith('CARFAX'));
    const hasCarfaxHref = carfaxProductTileFiltered?.[0]?.link?.href !== undefined;

    if (carfaxProductTileFiltered !== undefined && carfaxProductTileFiltered.length > 0 && hasCarfaxHref) {
        const carfaxProductTile = carfaxProductTileFiltered[0];
        const filteredTiles = listing?.productTiles?.filter((tile) => !tile?.tileType?.startsWith('CARFAX'));
        const updatedCarfaxProductTile = {
            ...carfaxProductTile,
            link: { ...carfaxProductTile.link, href: Buffer.from(carfaxProductTile.link.href).toString('base64') },
        };

        return [...filteredTiles, updatedCarfaxProductTile];
    }
    return listing.productTiles;
};

export const listingDataPreprocessing = ({
    displayPhoneNumber,
    hideOwnerName,
    inventoryImageUrl,
    listing,
    owners,
    vdpUrl,
    maxImageCount = 1,
}) => {
    const listingOwner = owners.find((owner) => owner.id === listing.ownerId);
    listing.vdpBaseUrl = vdpUrl && vdpUrl.toString().replace('?', `/${listing.id}?`);

    // Replace image server in source image URl
    replaceImageServerInUrl(listing, 'images.sources[0].src', inventoryImageUrl);
    replaceImageServerInUrl(listing, 'carSchemaOwnerImage', inventoryImageUrl);

    // Bypass phone number rules for standard listings in some experiences
    setPhoneVisible(listing, displayPhoneNumber);

    // Show 'Dealer' as ownerName in ATC SRP for used and certified standard listings
    showDealerAsOwnerName(listing, listingOwner, hideOwnerName);

    // Reduce the amount of data that we dispatch/gets stored in __NEXT_DATA__
    // First copy the correct phone number to the listing since it really applies there and not to the owner object.
    listing.phone = listing.owner?.phone;
    listing.owner = undefined;
    if (listing.images?.sources?.length > maxImageCount) {
        listing.images.sources.length = maxImageCount;
    }

    listing.productTiles = obfuscateCarfaxHref(listing);
};

export const getFourthSpotlightTier = (listings = []) => {
    if (listings.length > 19) {
        const _listings = listings.slice(0, 25);
        const { featured, standard } = _listings.reduce((counter, { priority = '' }) => {
            if (priority === '10') {
                counter.standard += 1;
            } else if (priority === '20') {
                counter.featured += 1;
            }
            return counter;
        }, { featured: 0, standard: 0 });

        if (featured > 0 || standard > 0) {
            return featured >= standard ? 'Featured' : 'Standard';
        }
        return false;
    }
    return false;
};

export default {
    addPsxValuePropImages,
    buildVDPBaseUrl,
    listingDataPreprocessing,
    replaceImageServerInUrl,
    replaceInventoryImageUrlServer,
    setPhoneVisible,
    showDealerAsOwnerName,
};
